import React from 'react';
import classnames from 'classnames';
import styles from './styles/Button.module.scss';

const Button = ({ className, disabled, onClick, text, type }) => {
  return (
    <button
      className={classnames('quashed-button', styles.button, className)}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
