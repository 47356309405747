import React from 'react';
import classNames from 'classnames';
import styles from './styles/InsuranceReasons.module.scss';
import ManageSvg from '../images/onboarding/manage-2.svg';
import ExploreSvg from '../images/onboarding/explore-2.svg';
import PurchaseSvg from '../images/onboarding/purchase-2.svg';
import BetterDealSvg from '../images/onboarding/better-deals-2.svg';

const INSURANCE_PURPOSE_OPTIONS = [
  {
    key: 'manage',
    text: 'Track my policies and spending',
    image: ManageSvg,
  },
  {
    key: 'explore',
    text: 'Explore insurance relevant to me',
    image: ExploreSvg,
  },
  {
    key: 'purchase',
    text: 'Purchase insurance policies online',
    image: PurchaseSvg,
  },
  // {
  //   key: 'adviser',
  //   text: 'Get help from an insurance adviser',
  //   image: PurchaseSvg,
  // },
  {
    key: 'betterDeals',
    text: 'Find me better insurance and deals',
    image: BetterDealSvg,
  },
];

const InsuranceReasons = ({ className, error, selected, onClick }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {INSURANCE_PURPOSE_OPTIONS.map(({ key, text, image: SVG }) => {
        const isSelected = selected.includes(text);
        return (
          <div
            key={key}
            className={classNames(
              styles.item,
              isSelected && 'selected',
              error && styles.error,
            )}
            onClick={() => onClick(text, !isSelected)}
          >
            {isSelected && (
              <span className={styles.circle}>
                <i className="fa-solid fa-check" />
              </span>
            )}

            <SVG />
            <div className={styles.name}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default InsuranceReasons;
