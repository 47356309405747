import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import styles from './styles/Onboarding.module.scss';
import Spinner from '../components/Spinner';
import { ANIMATION_LENGTH, INSURANCE_TYPES_LIFE } from '../constants';
import { ESTIMATED_SAVINGS } from '../constants/onboarding';
import InsuranceTypes from './InsuranceTypes';
import InsuranceReasons from './InsuranceReasons';
import { getRedirectUrl, segmentTrackEvent } from '../common/utils';
import Filler3V2Svg from '../images/v2/girl-finish-race.svg';
import Button from './Button';
import Lottie from './Lottie';

const defaultForm = {
  firstName: '',
  lastName: '',
  insuranceTypes: [],
  insuranceReasons: [],
};

const delayNext = (next, length = ANIMATION_LENGTH) => {
  const timer = setTimeout(() => next(), length);
  return () => clearTimeout(timer);
};

const Form2 = ({ className, form, next, showAll, onInsuranceSelect }) => {
  useEffect(() => segmentTrackEvent('Marketing-Get-Started-2'), []);

  const savings = form.insuranceTypes.reduce((acc, ins) => {
    return acc + (ESTIMATED_SAVINGS[ins] || 0);
  }, 0);

  return (
    <div className={classNames(styles.page, className)}>
      <div className={classNames('container', styles.spaceEven)}>
        <div>
          <div className={classNames(styles.title, styles.selectFormTitle)}>
            What kind of insurance do you want to compare?
          </div>
          <div className={styles.subtitle}>
            Select all that you are interested in
          </div>
        </div>
        <InsuranceTypes
          className={classNames(
            styles.insuranceTypes,
            !showAll && styles.short,
          )}
          selected={form.insuranceTypes}
          policyTypes={showAll ? undefined : INSURANCE_TYPES_LIFE}
          onClick={onInsuranceSelect}
        />
        <div className={styles.buttonContainer}>
          {showAll && (
            <div
              className={classNames(styles.savings, savings > 0 && styles.show)}
            >
              <i className="fa-solid fa-check" />
              Your total estimated savings ${savings}
            </div>
          )}
          <Button
            className={styles.submit}
            onClick={next}
            disabled={!form.insuranceTypes.length}
            text="Next"
            type="submit"
          />
          <Button
            className={styles.back}
            onClick={() => window.history.back()}
            text="Back"
            type="cancel"
          />
        </div>
      </div>
    </div>
  );
};

const Form3 = ({ className, form, setPage, next, onPurposeSelect }) => {
  useEffect(() => segmentTrackEvent('Marketing-Get-Started-3'), []);
  return (
    <div className={classNames(styles.page, className)}>
      <div className={classNames('container', styles.spaceEven)}>
        <div>
          <div className={classNames(styles.title, styles.selectFormTitle)}>
            What else is on your mind with insurance?
          </div>
          <div className={styles.subtitle}>Select one or more</div>
        </div>
        <InsuranceReasons
          className={styles.insuranceReasons}
          selected={form.insuranceReasons}
          onClick={onPurposeSelect}
        />
        <div className={styles.buttonContainer}>
          <Button
            className={styles.submit}
            onClick={next}
            text="Next"
            type="submit"
            disabled={!form.insuranceReasons.length}
          />
          <Button
            className={styles.back}
            onClick={() => setPage(2)}
            text="Back"
            type="cancel"
          />
        </div>
      </div>
    </div>
  );
};

const Filler3 = ({ className, redirectToApp }) => {
  useEffect(() => delayNext(redirectToApp), []);

  return (
    <>
      <div className={classNames(styles.page, styles.desktop, className)}>
        <Filler3V2Svg className={styles.svgV2} />
        <div className={classNames(styles.title, styles.filler3Title)}>
          Great, you’re almost there!
        </div>
        <Lottie className={styles.loadingDots} name="Loading_Dots" />
      </div>

      <div className={classNames(styles.page, styles.mobile, className)}>
        <div className={styles.content}>
          <img
            className={styles.logo}
            src="https://quashed.co.nz/auth0/white-logo.png"
          />
          <h1>
            We scan the market for <br /> the best insurance deals
          </h1>
          <p className={styles.mobileImgs}>
            Compare features of each policy to help you choose <br /> the best
            cover from NZ's top insurers.
          </p>
          <img src="https://quashed.co.nz/auth0/page2_v3.png" />
        </div>
        <div className={styles.loading}>
          <Spinner className={styles.spinner} />
          <p>Sign up and save today</p>
        </div>
      </div>
    </>
  );
};

const pageMap = {
  0: Form2,
  1: Filler3,
};

const Onboarding = ({ className, appUrl, eventName, life, redirectQuery }) => {
  const [page, setPage] = useState(0);
  const [form, setForm] = useState(defaultForm);
  const [showAll, setShowAll] = useState(!life);

  useEffect(() => {
    if (eventName) {
      segmentTrackEvent(eventName, { page: page + 1 });
    }
  }, [page]);

  const OnboardingPage = pageMap[page];

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleInsuranceSelect = useCallback(
    (insuranceType, isChecked) => {
      if (insuranceType === 'Other') {
        setShowAll(true);
      } else {
        const insuranceTypes = isChecked
          ? [...form.insuranceTypes, insuranceType]
          : form.insuranceTypes.filter((i) => i !== insuranceType);

        setForm({ ...form, insuranceTypes });
      }
    },
    [form],
  );

  const handlePurposeSelect = useCallback(
    (purpose, isChecked) => {
      const insuranceReasons = isChecked
        ? [...form.insuranceReasons, purpose]
        : form.insuranceReasons.filter((i) => i !== purpose);

      setForm({ ...form, insuranceReasons });
    },
    [form],
  );

  const next = useCallback(() => {
    window.scrollTo(0, 0);
    setPage((prev) => prev + 1);
  }, []);

  const redirectToApp = useCallback(() => {
    const redirectTo = getRedirectUrl(form, appUrl, redirectQuery);
    segmentTrackEvent('Marketing-Get-Started-Submitted');

    window.location.href = redirectTo;
  }, [appUrl, form, redirectQuery]);
  

  return (
    <OnboardingPage
      className={className}
      page={page}
      form={form}
      showAll={showAll}
      onInputChange={handleInputChange}
      onInsuranceSelect={handleInsuranceSelect}
      onPurposeSelect={handlePurposeSelect}
      next={next}
      redirectToApp={redirectToApp}
      setPage={setPage}
    />
  );
};

export default Onboarding;
