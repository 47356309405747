import { INSURANCE_TYPES_NAMES } from '../constants';

export const ESTIMATED_SAVINGS = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: 247,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: 447,
  [INSURANCE_TYPES_NAMES.HOME]: 415,
  [INSURANCE_TYPES_NAMES.LANDLORD]: 414,
  [INSURANCE_TYPES_NAMES.CONTENT]: 244,
  [INSURANCE_TYPES_NAMES.RENTERS]: 244,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: 243,
  [INSURANCE_TYPES_NAMES.LIFE]: 117,
};
