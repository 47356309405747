import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { useQueryParamString } from 'react-use-query-param-string';
import queryString from 'query-string';
import SEO from '../components/seo';
import Onboarding from '../components/Onboarding';
import LinkButton from '../components/LinkButton';
import useSiteMetadata from '../hooks/useSiteMetadata';
import styles from './styles/get-started.module.scss';
import Logo from '../components/Logo';
import { getSearchQuery, segmentTrackEvent } from '../common/utils';

const GetStarted = () => {
  const siteMetadata = useSiteMetadata();
  // redirectPath redirects to the url after both sign up or login
  const [redirectPath] = useQueryParamString('redirectPath', '');
  // loginUrl redirects to url after login only
  const [loginUrl] = useQueryParamString('loginUrl', '');
  // source either "Life" or none
  const { source } = queryString.parse(getSearchQuery());

  useEffect(() => {
    segmentTrackEvent('Marketing-GetStarted');
  }, []);

  return (
    <div className={styles.container}>
      <SEO title="Get Started" />
      <nav
        className={classnames(
          styles.nav,
          'navbar justify-content-between fixed-top',
        )}
      >
        <Link className={classnames('navbar-brand', styles.navLink)} to="/">
          <Logo />
        </Link>
        <LinkButton
          className={styles.signIn}
          href={`${siteMetadata.APP_URL}${loginUrl || redirectPath}`}
          background="#FFFFFF"
          track
        >
          Sign in
        </LinkButton>
      </nav>
      <Onboarding life={!!source} appUrl={siteMetadata.APP_URL} />
    </div>
  );
};

export default GetStarted;
