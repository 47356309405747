import React from 'react';
import classNames from 'classnames';
import styles from './styles/InsuranceTypes.module.scss';
import {
  INSURANCE_TYPES_NAMES,
  INSURANCE_TYPES_KEYS,
  INSURANCE_TYPES_LABELS,
} from '../constants';
import { INSURANCE_TYPES_ICONS_MIN } from '../constants/insurance-icons';

const typesToIgnore = [INSURANCE_TYPES_NAMES.CANCER];

const filteredTypeKeys = INSURANCE_TYPES_KEYS.filter(
  (k) => !typesToIgnore.includes(k),
);

const InsuranceTypes = ({
  className,
  policyTypes: policyTypesProps,
  selected,
  onClick,
}) => {
  const policyTypes = policyTypesProps || filteredTypeKeys;

  return (
    <div className={classNames(styles.container, className)}>
      {policyTypes.map((k) => {
        const SVG = INSURANCE_TYPES_ICONS_MIN[k];

        if (!SVG) {
          return null;
        }

        const isSelected = selected.includes(k);
        return (
          <div
            key={k}
            className={classNames(styles.item, isSelected && 'selected')}
            onClick={() => onClick(k, !isSelected)}
          >
            {isSelected && (
              <span className={styles.circle}>
                <i className="fa-solid fa-check" />
              </span>
            )}
            <SVG />
            <div className={styles.name}>{INSURANCE_TYPES_LABELS[k] || k}</div>
          </div>
        );
      })}
    </div>
  );
};

export default InsuranceTypes;
