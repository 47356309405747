import React from 'react';
import classnames from 'classnames';
import styles from './styles/Spinner.module.scss';

const Spinner = ({ className, inverted }) => {
  return (
    <div className={classnames(styles.loader, inverted && styles.inverted, className)} />
  );
};

export default Spinner;
